ol.progtrckr {
    list-style-type: none;
    padding: 0;
    display: none;
  }
  
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
  }
  
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
  }
  
  ol.progtrckr li.progtrckr-doing {
    color: #4d7c0f;
    border-bottom: 4px solid #CCCCCC;
  }
  
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #4d7c0f;
  }
  
  ol.progtrckr li:after {
    content: "\00a0\00a0";
  }
  
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.7rem;
    float: left;
    left: 50%;
  }
  
  ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
  }
  
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #CCCCCC;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #5cb85c;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }
  .btn-next{
    width: 150px;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 10px;
    font-size: 1.1rem !important;
    border: #3E7C17 !important;
    background: #3E7C17 0% 0% no-repeat padding-box !important;
    box-shadow: none !important;
    padding: 0.5rem 1rem;
    color: #fff;
  }
  .btn-prev{
    width: 150px;
    margin-top: 10px;
    margin-left: 0px;
    font-size: 1.1rem !important;
    margin-right: 10px;
    border: #3E7C17 !important;
    box-shadow: none !important;
    background: #3E7C17 0% 0% no-repeat padding-box !important; 
    padding: 0.5rem 1rem;
    color: #fff;
  }
  .multi-step{
      display: block;
  }
  .summary {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    background: #dee9d7 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5px;
    margin-left: 70%;
  }
  .sum-underline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1px;
    background-color: white;
  }
  .no-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .no-items span {
    text-align: left;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .sum-amt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sum-amt span {
    text-align: left;
    font: normal normal bold 15px/23px Poppins;
    letter-spacing: 0px;
    color: #3e7c17;
    opacity: 1;
  }