.order-table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
}
.order-table span {
  margin-left: 10px;
  text-align: left;
  font: normal normal bold 11px/17px Poppins;
  letter-spacing: 0px;
  color: #3e7c17;
  opacity: 1;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  
}
#customer table{
  display: flex;
}
#customers td,
#customers th {
  border: 1px solid #ddd;
  padding-top: 10px;
  
  text-align: center;
  background-color: lightgrey;
}
#customers td {
  border: none;
  padding-bottom: 10px;
}
#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  background-color: #dee9d7;
  font-size: 10px;
}
.ot-s {
  margin: 2px;
  padding: 0px;
  height: 25px;
  font-size: 10px;
}
.ot-in {
  border: 1px solid #4d7c0f;
  height: 25px;

}
.ot-input input {
  font-size: 10px;
}
.text-area {
  margin-top: 20px;
  margin-bottom: 20px;
}
.summary {
  /* width: 30%; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  background: #dee9d7 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 5px;
  margin-left: 70%;
}
.sum-underline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1px;
  background-color: white;
}
.no-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.no-items span {
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.sum-amt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.sum-amt span {
  text-align: left;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: 0px;
  color: #3e7c17;
  opacity: 1;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 3px;
    left: 6px;
    width: fit-content;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    padding-left: 18px;
    /* margin-bottom: 50px; */
  }

  /*
	Label the data
	*/
  td:nth-of-type(1):before {
    content: 'Select Product';
  }
  td:nth-of-type(2):before {
    content: 'Species';
  }
  td:nth-of-type(3):before {
    content: 'Thickness';
  }
  td:nth-of-type(4):before {
    content: 'Width';
  }
  td:nth-of-type(5):before {
    content: 'Length';
  }
  td:nth-of-type(6):before {
    content: 'Treatment';
  }
  td:nth-of-type(7):before {
    content: 'Qty';
  }
  td:nth-of-type(8):before {
    content: 'Price';
  }
  td:nth-of-type(9):before {
    content: 'Action';
  }

  #customers td {
    text-align: right;
  }
  .summary {
    margin-left: 0px;
  }
}
